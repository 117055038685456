<template>
  <div v-if="!isLoading">
    <GmapMap :center="center" :zoom="6" style="width: 100%; height: 500px">
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="toggleInfoWindow(m, index)"
      />
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <div v-html="infoContent"></div>
      </gmap-info-window>
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  props: ["shops"],
  data() {
    return {
      center: { lat: -17.8216, lng: 31.0492 },
      currentPlace: null,
      markers: [],
      places: [],
      isLoading: true,
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  mounted() {
    this.drawMarkers();
  }
  ,
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = this.getInfoWindowContent(marker);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    getInfoWindowContent: function (marker) {
      return `<div class="info">
        
            <div class="ps-product__container"><a class="ps-product__vendor" href="#">${marker.shop_name}</a>
            <hr>
                <div class="ps-product__content"><a class="ps-product__title" href="product-default.html">${marker.street}</a>                    
                    <p class="ps-product__price">${marker.phone_number}</p>
                    <a>
                </div>               
            </div>
        </div>`;
    },
    drawMarkers() {
      this.shops.forEach((shop) => {
        shop.addresses.forEach((address) => {
          if (address.latitude && address.longitude) {
            const marker = {
              lat: parseFloat(address.latitude),
              lng: parseFloat(address.longitude),
            };

            this.markers.push({
              position: marker,
              shop_name: address.name,
              phone_number: address.phone_number,
              street: address.street,
            });
          }
        })
      });
      this.isLoading = false;
    },
    // addMarker() {
    //   if (this.currentPlace) {
    //     const marker = {
    //       lat: this.currentPlace.geometry.location.lat(),
    //       lng: this.currentPlace.geometry.location.lng(),
    //     };
    //     this.markers.push({ position: marker });
    //     this.places.push(this.currentPlace);
    //     this.center = marker;
    //     this.currentPlace = null;
    //   }
    // },
    // geolocate: function () {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     this.center = {
    //       lat: position.coords.latitude,
    //       lng: position.coords.longitude,
    //     };
    //   });
    // },
  },
};
</script>
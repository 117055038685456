<template>
  <div class="ps-section__right">
    <form class="ps-form--account-setting" method="get">
      <div class="ps-form__header">
        <h3>Create {{ addressType }} Address</h3>
      </div>
      <AddressForm :address-type="addressType" ref="addressForm"></AddressForm>
    </form>

  </div>
</template>

<script>
import axios from "axios";
import AddressForm from "./AddressForm";

export default {
  name: "CreateAddress",
  components: {AddressForm},
  data() {
    return {
      addressSaved: false,
      loading: false,
      addressType: null,
      address: {
        email: "",
        phone_number: "",
        street: '',
        country: '',
        city: ''
      },
      errors: null
    };
  },
  mounted() {
    this.addressType = this.$route.params['address_type'] === 'billing' ? 'Billing' : 'Shipping';
  },
  methods: {
  }
}
</script>

<style scoped>

</style>